@import 'general';

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin:0; padding:0;
}
:focus{
  outline:none;
}
.row:before, .row:after, .clearfix:before, .clearfix:after, .horz-list:after, .horz-list:before { content:""; display:table; }
.row:after, .clearfix:after, .horz-list:after { clear: both; }
.row, .clearfix, .horz-list { zoom: 1; }

// a different sort of clearfix
.clearfix {
    &:before,
    &:after {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    zoom: 1;
}

::-webkit-input-placeholder {
  color:$text_color;
}
::-moz-placeholder {
  color:$text_color;
}
:-ms-input-placeholder {
  color:$text_color;
}
:-moz-placeholder {
  color:$text_color;
}

::-moz-placeholder {
  opacity: 1;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale
}

img, a img{
	vertical-align: top;
  outline:none;
  border:none;
}
body{
  background:#F7F7FC;
  margin: 0px;
  padding: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
}
body, td, th, select, input, textarea {
  font-family:$font-regular;
  font-size:15px;
  line-height:1.45;
  color:$text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {color:$red; text-decoration: none;}
a:visited {text-decoration: none;}
a:hover {text-decoration: none;}
a:active {text-decoration: none;}

// Import Layout

@import 'layout';


// Import Media Queries

@import 'media-queries';



// Import Cross-browser Compaitibility Files

@import 'ie8', 'ie9';

  .modal-right-overlay {
    position: fixed;
    right: 10px;
    background-color: white;
    width: 310px;
    padding: 20px;

    margin-right: 10px;
    border: 1px solid rgba(20, 21, 22, 0.2);
    border-radius: 0.3rem;
    height: 80%;
    overflow-y: auto;

}




