@font-face {
  font-family: 'proxima_nova_blblack';
  src: url('../fonts/proximanova-black-webfont.eot');
  src: url('../fonts/proximanova-black-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proximanova-black-webfont.woff2') format('woff2'),
  url('../fonts/proximanova-black-webfont.woff') format('woff'),
  url('../fonts/proximanova-black-webfont.ttf') format('truetype'),
  url('../fonts/proximanova-black-webfont.svg#proxima_nova_blblack') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'proxima_novabold';
  src: url('../fonts/proximanova-bold-webfont.eot');
  src: url('../fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proximanova-bold-webfont.woff2') format('woff2'),
  url('../fonts/proximanova-bold-webfont.woff') format('woff'),
  url('../fonts/proximanova-bold-webfont.ttf') format('truetype'),
  url('../fonts/proximanova-bold-webfont.svg#proxima_novabold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_nova_thextrabold';
  src: url('../fonts/proximanova-extrabold-webfont.eot');
  src: url('../fonts/proximanova-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proximanova-extrabold-webfont.woff2') format('woff2'),
  url('../fonts/proximanova-extrabold-webfont.woff') format('woff'),
  url('../fonts/proximanova-extrabold-webfont.ttf') format('truetype'),
  url('../fonts/proximanova-extrabold-webfont.svg#proxima_nova_thextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'proxima_novaregular';
  src: url('../fonts/proximanova-regular-webfont.eot');
  src: url('../fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proximanova-regular-webfont.woff2') format('woff2'),
  url('../fonts/proximanova-regular-webfont.woff') format('woff'),
  url('../fonts/proximanova-regular-webfont.ttf') format('truetype'),
  url('../fonts/proximanova-regular-webfont.svg#proxima_novaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novasemibold';
  src: url('../fonts/proximanova-semibold-webfont.eot');
  src: url('../fonts/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proximanova-semibold-webfont.woff2') format('woff2'),
  url('../fonts/proximanova-semibold-webfont.woff') format('woff'),
  url('../fonts/proximanova-semibold-webfont.ttf') format('truetype'),
  url('../fonts/proximanova-semibold-webfont.svg#proxima_novasemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novathin';
  src: url('../fonts/proximanova-thin-webfont.eot');
  src: url('../fonts/proximanova-thin-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proximanova-thin-webfont.woff2') format('woff2'),
  url('../fonts/proximanova-thin-webfont.woff') format('woff'),
  url('../fonts/proximanova-thin-webfont.ttf') format('truetype'),
  url('../fonts/proximanova-thin-webfont.svg#proxima_novathin') format('svg');
  font-weight: normal;
  font-style: normal;
}



// Colours
$white: #fff;
$black:#141516;
$red:#FF4612;
$light-green:#ADF0CC;
$green: #46a00d;
$grey:#4a4e53;
$purple:#7171A6;
$light_grey:#e3e2df;
$gutter:60px;
$text_color:#606060;
$border_dark: #cac9c8;

// fonts
$font-light: 'proxima_novathin', Helvetica, sans-serif;
$font-regular: 'proxima_novaregular', Helvetica, sans-serif;
$font-semibold: 'proxima_novasemibold', Helvetica, sans-serif;
$font-bold: 'proxima_novabold', Helvetica, sans-serif;
$font-heavy: 'proxima_nova_thextrabold', Helvetica, sans-serif;


// Mixins
@mixin box-sizing{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
       -o-border-radius: $radius;
          border-radius: $radius;
}

@mixin animaxn{
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.vertical-align {
    display: table-cell;
    vertical-align: middle;
    position: relative;
}
.inline-block{
    display:inline-block;
    *display:inline;
    zoom:1;
    vertical-align:top;
}
.display-table{
    height:100%;
    display:table;
    width:100%;
}
