.wrap {
  max-width: 1270px;
  margin: auto;
}

.flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@-webkit-keyframes infinite-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes infinite-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.full-width {
  @extend .clearfix;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.hide {
  display: none;
}

.full-bg {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.login_screen {
  background: $white;
  height: 100vh;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -385.5px;
    height: 771px;
    width: 1855px;
    background: url("../images/login_pattern.png") no-repeat center right;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.home_background {
  height: 100vh;
  width: 100%;
  background: url("../images/login_pattern.png") no-repeat center right;
}

.reset_screen {
  background: $white;
  height: 100vh;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -385.5px;
    height: 771px;
    width: 1855px;
    background: url("../images/login_pattern.png") no-repeat center right;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.login_sheet {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 380px;
  background: $white;
  padding: 30px;
  @include border-radius(10px);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  .form_group {
    margin-bottom: 20px;
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"] {
      border: #e6e6ff solid 2px;
      color: #9494ce;
      &.with_email_icon {
        padding-left: 45px;
        background: url("../images/email_icon.svg") no-repeat 15px center;
      }
      &.with_lock_icon {
        padding-left: 45px;
        background: url("../images/lock_icon.svg") no-repeat 20px center;
      }
    }
  }
  ::-webkit-input-placeholder {
    color: #9494ce;
  }
  ::-moz-placeholder {
    color: #9494ce;
  }
  :-ms-input-placeholder {
    color: #9494ce;
  }
  :-moz-placeholder {
    color: #9494ce;
  }
}
.logo_login {
  max-width: 172px;
  margin-bottom: 30px;
}

.form_group {
  margin-bottom: 25px;
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"] {
    border: #e6e6ff solid 1px;
    @include border-radius(5px);
    opacity: 0.9;
    height: 45px;
    width: 100%;
    padding: 10px 20px;
  }
  input[type="submit"] {
    border: none;
    background: $red;
    color: $white;
    padding: 2px 33px;
    height: 47px;
    cursor: pointer;
    font-size: 1.167em;
    @include animaxn;
    @include border-radius(25px);
    text-transform: uppercase;
    &:hover {
      background: $grey;
      @include border-radius(5px);
    }
  }
}

.forgot_pass {
  a {
    font-family: $font-semibold;
    font-style: italic;
    color: $text_color;
    text-decoration: underline;
    &:hover {
      color: $red;
    }
  }
}

.notification {
  position: relative;
  padding: 8px 15px;
  margin-bottom: 20px;
  font-size: 0.929em;
  color: $white;
  @include border-radius(3px);
  border: transparent solid 1px;
  text-align: left;
  &.info {
    background-color: #17a2b8;
  }
  &.warning {
    background-color: #ffc107;
  }
  &.error {
    background-color: #fbeaea;
    border-color: #f4d1d1;
    color: #c02b2e;
  }

  &.success {
    background-color: #81bc01;
  }
}

.flex_container {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  margin: 0 -15px;
}

.flex_item {
  display: inline-block;
  margin: 0 15px 0px;
  width: 18em;
  height: 14em;
}

.sector_card {
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  @include border-radius(10px);
  display: block;
  text-align: center;
  padding: 2em;
  color: $text_color;
  position: relative;
  @include animaxn;
  margin-bottom: 30px;
  img {
    margin-bottom: 0.9em;
    height: 4em;
  }
  h4 {
    font-family: $font-regular;
    font-weight: normal;
    font-size: 1.2em;
  }
  .notice {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: $red;
    font-family: $font-semibold;
    @include border-radius(50%);
    color: $white;
    position: absolute;
    right: 15px;
    top: -16px;
  }
  &:hover {
    background: #ececf1;
    @include border-radius(4px);
  }
}

.powered_by.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px 0;
  background: $white;
  text-align: center;
}

.options_screen {
  height: calc(100vh - 71px);
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "Sidebar Modules";
}

.Sidebar {
  grid-area: Sidebar;
  align-self: start;
}

.Modules_area {
  grid-area: Modules;
  // display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-self: start;
  padding: 2.6em 0em 0em 1.6em;
}

.excerpt {
  max-width: 400px;
  margin-bottom: 40px;
  h2 {
    font-family: $font-bold;
    font-weight: normal;
    font-size: 26px;
  }
}

//Dashboard Styling starts

.app_wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  min-height: 0;
  transition: left 0.2s ease-in-out;
  position: relative;
  height: 100vh;
}

@media screen and (max-width: 600px){
  .app_navigation {
    visibility: hidden;
    clear: both;
    display: none;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 250px;
  }
}

.app_content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 2 0 0%;
  flex: 2 0 0%;
  overflow: auto;
}

.switcher_toggle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  height: 48px;
  padding: 0 16px;
  background: #e3e2df;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
  .item_svg_icon {
    width: 14px;
    height: 14px;
    right: 20px;
    position: absolute;
    font-size: 14px;
    opacity: 0.4;
    -webkit-transform: rotateZ(-90deg);
    transform: rotateZ(-90deg);
    transition: all 0.2s;
    color: #4d6575;
  }
  &:hover {
    .item_svg_icon {
      right: 15px;
    }
  }
}

.profile_switcher {
  background: #f7f7fc;
  padding: 8px;
  &.is-open {
    .profile_switcher__panel {
      opacity: 1;
      visibility: visible;
      left: 8px;
    }
    .switcher_toggle .item_svg_icon {
      -webkit-transform: rotateZ(0deg);
      transform: rotateZ(0deg);
    }
  }
}

.sidebar_header {
  padding: 8px;
  display: flex;
  justify-content: center;
  .partner_switcher {
    display: flex;
    // position: relative;
    &.open {
      .partner_list {
        display: block;
        margin-top: 10px;
        margin-left: 20px;
        opacity: 1;
        z-index: 999;
      }
    }
    .partner_toggle {
      display: flex;
      align-items: center;
      // transition: all .5s;
      &:hover {
        svg {
          margin-top: 5px;
          opacity: 0.7;
        }
      }
      svg {
        margin-left: 10px;
        width: 23px;
        height: 23px;
        opacity: 0.4;
      }
    }
    .partner_list {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 60px;
      background-color: white;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 12px;
      padding: 15px 0;
      min-width: 330px;
      display: none;
      margin-top: -125%;
      max-height: 60vh;
      overflow-y: auto;
      opacity: 0;
      transition: margin-top 0.5s ease, opacity 0.5s ease;
      li {
        margin: 8px 14px 0;
        padding-bottom: 8px;
        border-bottom: #ddd solid 1px;
        display: flex;
        align-items: center;
        &.input {
          padding: 0 10px 10px;
          margin: 0;
          border-bottom: none;
          // position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 20px;
            width: 23px;
            height: 22px;
            background: url('../images/search_icon.svg') no-repeat left
              center;
          }
          input {
            width: 100%;
            padding: 5px 10px 5px 44px;
            font-size: 0.9rem;
            border: #ddd solid 1px;
            border-radius: 5px;
          }
        }
        span {
          display: inline-flex;
          &.image {
            width: 30px;
            height: 30px;
            padding: 5px;
          }
          &.text {
            margin-left: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.profile_switcher__panel {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 8px;
  background: $white;
  left: 0;
  z-index: 1015;
  min-width: 400px;
  max-width: 600px;
  padding: 20px;
  border-radius: 24px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 36px 4px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease, opacity 0.3s ease;
}

.item_svg_icon.cog_icon {
  left: 20px;
  width: 24px;
  height: 24px;
  color: $red;
  fill: currentColor;
  opacity: 1;
}

.user_name {
  font-family: $font-semibold;
  color: #616161;
  padding-left: 44px;
}

.panel__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 20px;
  .user_name {
    font-size: 24px;
    padding-left: 0;
    font-family: $font-regular;
  }
}

.user_avatar {
  width: 68px;
  height: 68px;
  display: block;
  border: dashed #ddd 1px;
  @include border-radius(50%);
  position: relative;
  text-align: center;
  margin-right: 15px;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.close_profiler {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  opacity: 0.5;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 2px;
    height: 12px;
    width: 2px;
    left: 11px;
    top: 0;
    height: 24px;
    background-color: #868582;
  }
  &:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &:hover {
    opacity: 1;
  }
}

.panel_section {
  margin: 15px 0;
  border-bottom: 1px solid #d4dde3;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
  &.other_nav {
    .panel_nav {
      li {
        @extend .inline-block;
        margin: 0;
        padding-right: 30px;
        position: relative;
        a {
          font-family: $font-regular;
        }
        &:after {
          content: "•";
          position: absolute;
          right: 15px;
        }
        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.panel_nav {
  list-style-type: none;
  li {
    display: block;
    margin: 15px 0;
    a {
      color: $text_color;
      font-family: $font-semibold;
      .panel_nav_icon {
        @extend .inline-block;
        color: $red;
        margin-right: 6px;
        margin-left: -3px;
        vertical-align: middle;
        position: relative;
        bottom: 1px;
        width: 20px;
        height: 20px;
        fill: currentColor;
      }
      &:hover {
        color: $red;
      }
    }
  }
}

.text_exclude {
  strong {
    font-family: $font-bold;
    font-weight: normal;
  }
}

.app_primary_nav {
  height: 100%;
  overflow-y: auto;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  color: #1c252c;
  background: #e3e2df;
  border-top-right-radius: 23px;
  &::-webkit-scrollbar {
    width: 6px;
    margin: 5px;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    margin: 2px;
    border-radius: 10px;
    background-color: #c1c1c1;
  }
}

.help_btn {
  background: #c1c1c1;
  color: #606060;
  padding: 8px 45px;
  border: #ddd solid 1px;
  @include border-radius(25px);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100px;
  margin: auto;
  margin-bottom: 20px;
  font-family: $font-semibold;
  @include animaxn;
  img {
    width: 24px;
    margin-right: 10px;
  }
  &:hover {
    background: $white;
  }
}

.app_footer {
  border-top: #ccc solid 1px;
  text-align: center;
  padding: 16px 0;
  font-size: 13px;
  background: #e3e2df;
  transition: right 0.2s ease-in-out;
}

.powered_by {
  display: block;
  font-size: 13px;
}

.nav_menu {
  list-style: none;
  padding-top: 10px;
  .menu_item {
    color: #4a4e53;
    font-weight: normal;
  }
  .menu_item_link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
    position: relative;
    cursor: pointer;
    padding: 12px 32px 12px 64px;
    &:hover {
      .menu_item_label,
      .item_svg_icon {
        color: $red;
      }
    }
  }
  .item_svg_icon {
    position: absolute;
    left: 24px;
    top: calc(50% - 14px);
    display: inline-block;
    color: #b2b2b2;
    width: 26px;
    height: 26px;
    fill: currentColor;
  }
  .menu_item_label {
    font-weight: normal;
    color: inherit;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
}

.menu_item_expandable_content {
  overflow: hidden;
}

.menu_item_expandable {
  &.open {
    .menu_item {
      margin-top: 0;
      opacity: 1;
      transition-delay: 0.3s;
    }
    .menu_nested {
      margin-top: 0;
      opacity: 1;
    }
    .menu_item_caret {
      -webkit-transform: rotateZ(180deg);
      transform: rotateZ(180deg);
    }
    .menu_item_label {
      font-family: $font-bold;
      color: $red;
    }
    .item_svg_icon {
      color: $red;
    }
  }
}

.menu_nested {
  padding-bottom: 4px;
  margin-top: -425%;
  margin-bottom: 0;
  opacity: 0;
  transition: margin-top 0.5s ease, opacity 0.5s ease;
  .menu_item {
    margin-top: 0;
    opacity: 1;
    transition-delay: 0.3s;
  }
  .menu_item_link {
    padding: 6px 0 6px 64px;
    font-size: 14px;
    &:hover {
      font-family: $font-semibold;
      color: #4a4e53;
    }
  }
}

.menu_item_caret {
  width: 12px;
  height: 12px;
  top: calc(50% - (15px / 2));
  position: absolute;
  right: 16px;
  color: #4d6575;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.spinner_centered {
  position: relative;
  width: 100%;
  height: 100%;
}

.wv_spinner {
  border-style: solid;
  border-radius: 50%;
  border-color: #e3e2df;
  border-right-color: #4a4e53;
  border-top-color: #4a4e53;
  border-width: 5px;
  font-size: 60px;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-animation: spin-animation 0.7s linear infinite;
  animation: spin-animation 0.7s linear infinite;
}

@-webkit-keyframes spin-animation {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin-animation {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.svg_hidden {
  display: none;
}

.app_header {
  min-height: 90px;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 10;
  transition: all 0.2s;
  //position: fixed;
  width: 100%;
  padding: 0 40px;
  border-bottom: #e3e2df solid 1px;
}

.app_header_content {
  width: 100%;
  min-height: 90px;
  display: flex;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

@media (max-width: 600px){
.app_header_content {
  width: 100%;
  min-height: 90px;
  margin-top: 20px;
  display: flex;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
}

.dropdown-menu {
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100%;
  margin: 2px 0 0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  li {
    display: block;
    &:last-of-type {
      border: none;
      a {
      }
    }
    a {
      display: block;
      padding: 7px 20px;
      @include animaxn;
      color: $text_color;
      &:hover {
        background-color: #f0f4fa;
      }
    }
  }
}

.app_header_left {
  position: relative;
  display: flex;
  align-items: center;
  .module_switcher_panel {
    position: absolute;
    left: 0;
    top: 60px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 15px 0;
    min-width: 330px;
    display: none;
    margin-top: -125%;
    max-height: 85vh;
    overflow-y: auto;
    opacity: 0;
    transition: margin-top 0.5s ease, opacity 0.5s ease;
    &::-webkit-scrollbar {
      width: 6px;
      margin: 5px;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      margin: 2px;
      border-radius: 10px;
      background-color: #c1c1c1;
    }
  }
  &.open {
    .module_switcher_panel {
      display: block;
      margin-top: 0;
      opacity: 1;
    }
    .sr_heading_title .sr_heading_title_icon {
      -webkit-transform: rotateZ(180);
      transform: rotateZ(180deg);
    }
  }
}

.dropdown_menu_item {
  position: relative;
  padding: 14px 25px;
  display: block;
  font-weight: normal;
  color: #606060;
  display: flex;
  position: relative;
  align-items: center;
  height: 62px;
  &:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    background: $red;
    top: 0;
    right: 0;
    display: none;
  }
  .dropdown_menu_item_icon {
    width: 30px;
    margin-right: 15px;
    @extend .inline-block;
    img {
      width: 100%;
    }
  }

  .dropdown_menu_item_label {
    @extend .inline-block;
  }

  &:hover {
    background-color: #f7f7fc;
    &:after {
      display: block;
    }
  }

  &.is-current {
    background-color: #f7f7fc;
    font-family: $font-semibold;
    &:after {
      display: block;
    }
  }
}

.app_header_right {
  margin-left: auto;
  display: flex;
  align-items: center;
  .button_primary {
    margin-right: 1.2rem;
  }
  .dropdown-menu {
    min-width: 180px;
  }
}

.sr_heading_title {
  cursor: pointer;
  margin-right: 40px;
  .sr_heading_title_label {
    color: $red;
    font-size: 36px;
  }
  .sr_heading_title_icon {
    @extend .inline-block;
    color: #686868;
    margin-left: 4px;
    vertical-align: middle;
    position: relative;
    bottom: 8px;
    width: 30px;
    height: 30px;
    fill: currentColor;
    opacity: 0.6;
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
    transition: all 0.2s;
  }
}

.notification_panel {
  position: relative;
  .notification_toggle {
    padding: 10px 25px;
    line-height: 38px;
    font-size: 14px;
    font-family: $font-semibold;
    border: #b2b2b2 solid 2px;
    color: #606060;
    cursor: pointer;
    border-radius: 28px;
    display: flex;
    position: relative;
    align-items: center;
    height: 46px;
    transition: background-color 0.3s ease, color 0.3s ease;
    .has_count {
      position: absolute;
      right: 0;
      top: -10px;
      padding: 4px 6px;
      line-height: 12px;
      font-size: 13px;
      color: $white;
      font-family: $font-bold;
      border-radius: 11px;
      background: $red;
    }
    &:hover {
      border-color: $red;
      background: $red;
      color: $white;
    }
  }
}

.notification_widget,
.options_side {
  position: absolute;
  right: -340px;
  top: 0;
  opacity: 0;
  width: 500px;
  min-height: 40vh;
  max-height:40vh;
  overflow-y:scroll;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 999;
  transition: right 0.3s ease, opacity 0.3s ease;
  .notification_widget_header {
    border-bottom: #e3e2df solid 1px;
    padding: 14px 25px;
    position: relative;
    height: 50px;
  }
  .notif_tab_menu {
    list-style-type: none;
    border-bottom: #e3e2df solid 1px;
    text-align: center;
    padding: 0 5px;
    li {
      @extend .inline-block;
      padding: 0 8px;
      a {
        padding: 15px 10px;
        display: block;
        position: relative;
        font-family: $font-semibold;
        color: $text_color;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          background: $red;
          bottom: 0;
          right: 0;
          display: none;
        }
        .count {
          background: #ff4512;
          padding: 3px 5px;
          line-height: 10px;
          color: #fff;
          border-radius: 11px;
          font-size: 12px;
          margin-top: 1px;
          margin-left: 3px;
          @extend .inline-block;
        }
      }
      &.active {
        a {
          color: $red;
          &:after {
            display: block;
          }
        }
      }
    }
  }
}

.options_side {
  opacity: 1;
  width: 30em;
  left: 0px;
  position: relative;
  padding: 0 1em;

  .logo_area {
    padding: 2em;
  }

  .notification_widget_header {
    border-bottom: #e3e2df solid 1px;
    padding: 14px 25px;
    position: relative;
    height: 50px;
  }
  .notif_tab_menu {
    list-style-type: none;
    border-bottom: #e3e2df solid 1px;
    text-align: center;
    padding: 0 5px;
    li {
      @extend .inline-block;
      padding: 0 8px;
      a {
        padding: 15px 10px;
        display: block;
        position: relative;
        font-family: $font-semibold;
        color: $text_color;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          background: $red;
          bottom: 0;
          right: 0;
          display: none;
        }
        .count {
          background: #ff4512;
          padding: 3px 5px;
          line-height: 10px;
          color: #fff;
          border-radius: 11px;
          font-size: 12px;
          margin-top: 1px;
          margin-left: 3px;
          @extend .inline-block;
        }
      }
      &.active {
        a {
          color: $red;
          &:after {
            display: block;
          }
        }
      }
    }
  }
}

.notif-open {
  .notification_widget {
    right: 30px;
    top:80px;
    border-radius:20px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }
}


.tab_pane {
  display: none;
  padding: 20px;
}


.tab-content {
  // max-height: 80vh;
  overflow-y: auto;
  transition: margin-top 0.5s ease, opacity 0.5s ease;
  &::-webkit-scrollbar {
    width: 6px;
    margin: 5px;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    margin: 2px;
    border-radius: 10px;
    background-color: #c1c1c1;
  }
  > .tab_pane {
    display: none;
  }
  > .active {
    display: inline-block;
    width: 100%;
  }
}

.single_news {
  border-bottom: #e3e2df solid 1px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.date_posted {
  font-size: 12px;
  font-family: $font-semibold;
  font-weight: normal;
  margin-bottom: 8px;
}

.news_title {
  font-family: $font-bold;
  font-weight: normal;
  margin-bottom: 12px;
  font-size: 16px;
  padding-left: 30px;
  background: url("../images/arrow_right.svg") left center no-repeat;
}

.news_excerpt {
  font-size: 13px;
  margin-bottom: 10px;
  ul {
    margin: 0;
    list-style-type: none;
    li {
      margin-bottom: 5px;
      &:before {
        content: "\2014";
      }
    }
  }

  .inline_img {
    float: left;
    width: 100%;
    max-width: 110px;
    margin-right: 10px;
  }
}

.learn_more {
  font-size: 14px;
  font-family: $font-semibold;
  font-weight: normal;
  color: #3b88fd;
  .sr_heading_title_icon {
    color: #3e8afc;
    margin-left: 0px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
    width: 20px;
    height: 20px;
    fill: currentColor;
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(-90deg);
    transition: all 0.2s;
  }
  &:hover {
    color: $red;
    .sr_heading_title_icon {
      margin-left: 3px;
      color: $red;
    }
  }
}

.at_svg_icon {
  color: #4a4e53;
  margin-left: 0px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
  width: 20px;
  height: 20px;
  fill: currentColor;
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(-90deg);
  transition: all 0.2s;
}

.subscribe_news {
  border: #ddd dashed 1px;
  text-align: center;
  margin-bottom: 23px;
  padding: 5px;
  background: #f7f7fc;
  h4 {
    font-size: 16px;
    font-family: $font-semibold;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .check_form {
    font-size: 14px;
  }
}

.section_title {
  color: $red;
  font-size: 20px;
  font-family: $font-semibold;
  font-weight: normal;
  margin-bottom: 10px;
}

.escalation_list {
  list-style-type: none;
  margin-bottom: 25px;
  .dropdown_menu_item {
    height: 48px;
    border-bottom: #ddd solid 1px;
    padding: 5px 10px;
    &:after {
      display: none !important;
    }
    .count {
      background: #ff4512;
      padding: 2px 5px;
      line-height: 11px;
      font-family: $font-semibold;
      color: #fff;
      border-radius: 11px;
      font-size: 12px;
      margin-top: 1px;
      margin-left: 3px;
      @extend .inline-block;
    }
  }
}

.app_breadcrumb {
  padding: 10px 40px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  height: 45px;
  font-size: 14px;
  border-bottom: #ddd solid 1px;
}
.app_breadcrumb_item {
  list-style-type: none;
  li {
    @extend .inline-block;
    color: $red;
    position: relative;
    padding-right: 24px;
    &:after {
      content: "/";
      position: absolute;
      top: 0;
      right: 10px;
      color: $text_color;
    }
    &:last-of-type {
      &:after {
        display: none;
      }
    }
    a {
      display: block;
      color: $text_color;
      &:hover {
        color: $red;
      }
    }
  }
}

.last_update_sticker_refresh {
  .at_svg_icon {
    margin-left: 5px;
    opacity: 0.8;
  }
  &:hover {
    .at_svg_icon {
      color: $red;
      opacity: 1;
    }
  }
}

.button_primary {
  background: $white;
  border: #b2b2b2 solid 2px;
  color: #606060;
  cursor: pointer;
  font-size: 14px;
  font-family: $font-semibold;
  padding: 14px 25px;
  border-radius: 28px;
  transition: background-color 0.3s ease, color 0.3s ease;
  &:hover {
    border-color: $red;
    background: $red;
    color: $white;
  }
}

.app_global_search {
  border: #e3e2df solid 1px;
  padding-left: 50px;
  border-radius: 28px;
  width: 490px;
  height: 42px;
  overflow: hidden;
  position: relative;
  .search_input {
    width: 100%;
    padding: 0 100px 0 55px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    -webkit-transform: translate(0, 60px);
    transform: translate(0, 60px);
    transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
    transition-delay: 0.3s;
    font-size: 0.88rem;
    opacity: 1;
    -webkit-transform: translate(0, 11px);
    transform: translate(0, 11px);
  }
  .search_btn {
    width: 150px;
    height: 42px;
    margin: 0;
    border-radius: 0;
    position: relative;
    z-index: 2;
    float: right;
    cursor: pointer;
    font-family: $font-semibold;
    background: rgb(109, 112, 117);
    border: none;
    color: #fff;
    &:hover {
      background: $red;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    width: 30px;
    height: 100%;
    background: url('../images/search_icon.svg') no-repeat left center;
  }
}

.app_table_select {
  border: #e3e2df solid 1px;
  padding-left: 50px;
  border-radius: 10px;
  margin-top: 20px;
  width: 160px;
  height: 42px;
  overflow: hidden;
  position: relative;
  .select_input {
    width: 100%;
    padding: 0 10px 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    -webkit-transform: translate(0, 60px);
    transform: translate(0, 60px);
    transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
    transition-delay: 0.3s;
    font-size: 0.88rem;
    opacity: 1;
    -webkit-transform: translate(0, 11px);
    transform: translate(0, 11px);
  }
  &:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    width: 30px;
    height: 100%;
  }
}

.app_table_date {
  border: #e3e2df solid 1px;
  padding-left: 50px;
  border-radius: 10px;
  width: 160px;
  height: 42px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  .date_input {
    width: 100%;
    padding: 0 10px 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    -webkit-transform: translate(0, 60px);
    transform: translate(0, 60px);
    transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
    transition-delay: 0.3s;
    font-size: 0.88rem;
    opacity: 1;
    -webkit-transform: translate(0, 11px);
    transform: translate(0, 11px);
  }
}

.app_table_datetime {
  border: #e3e2df solid 1px;
  padding-left: 50px;
  border-radius: 10px;
  width: 160px;
  height: 42px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  .datetime_input {
    width: 100%;
    padding: 0 10px 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    -webkit-transform: translate(0, 60px);
    transform: translate(0, 60px);
    transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
    transition-delay: 0.3s;
    font-size: 0.88rem;
    opacity: 1;
    -webkit-transform: translate(0, 11px);
    transform: translate(0, 11px);
  }
}

.app_table_search {
  border: #e3e2df solid 1px;
  padding-left: 50px;
  border-radius: 10px;
  width: 200px;
  height: 42px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  .search_input {
    width: 100%;
    padding: 0 10px 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    -webkit-transform: translate(0, 60px);
    transform: translate(0, 60px);
    transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
    transition-delay: 0.3s;
    font-size: 0.88rem;
    opacity: 1;
    -webkit-transform: translate(0, 11px);
    transform: translate(0, 11px);
  }
  .search_btn {
    width: 150px;
    height: 42px;
    margin: 0;
    border-radius: 0;
    position: relative;
    z-index: 2;
    float: right;
    cursor: pointer;
    font-family: $font-semibold;
    background: rgb(109, 112, 117);
    border: none;
    color: #fff;
    &:hover {
      background: $red;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    width: 30px;
    height: 100%;
  }
}

.label_title{
  font-weight: bold;
}

.form_group_bulk {
  width: 129%;
  margin-bottom: 0;
}

.form_group_modal {
  width: 150%;
  margin-bottom: 0;
}

.form_group_modal input {
  border: #e6e6ff solid 1px;
  border-radius: 5px;
  opacity: 0.9;
  height: 45px;
  width: 90%;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.form_group_bulk input {
  border: #e6e6ff solid 1px;
  border-radius: 5px;
  opacity: 0.9;
  height: 45px;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.form_group_modal select {
  font-size: 19px;
  border: none;
  background-color: transparent;
  border: #e6e6ff solid 1px;
  border-radius: 5px;
  opacity: 0.9;
  height: 45px;
  width: 90%;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.form_group_modal textarea {
  border: #e6e6ff solid 1px;
  border-radius: 5px;
  opacity: 0.9;
  height: 150px;
  width: 95%;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.button_success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 28px;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  margin: 0 20px 0 auto;
}

.button_secondary {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 28px;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  margin: 0 20px 0 auto;
}

.button_secondary_grey {
  color: #fff;
  background-color: #c1c1c1;
  border-color: none;
  border-radius: 28px;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  margin: 0 20px 0 auto;
}

.button_danger {
  color: #fff;
  background-color: #ff4512;
  border-color: none;
  border-radius: 28px;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  margin: 0 20px 0 auto;
}



.app_table_layout {
  padding: 0 40px 40px;
  overflow-y: auto;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  position: relative;
  &::-webkit-scrollbar {
    width: 6px;
    margin: 5px;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    margin: 2px;
    border-radius: 10px;
    background-color: #c1c1c1;
  }
}

.datagrid {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  transition: all 0.2s;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
    margin-bottom: 25px;
    td,
    th {
      padding: 14px 6px;
    }
    thead,
    tfoot {
      th {
        color: #4a4e53;
        font-family: $font-semibold;
        border-bottom: #ddd solid 2px;
      }
    }
    thead {
      th {
        cursor: pointer;
        position: relative;
        &.sorting_desc {
          color: $purple;
          //&:before{
          //  content: "";
          //  position: absolute;
          //  right: 70%;
          //  top:20px;
          //  width: 0;
          //  height: 0;
          //  opacity:0.4;
          //  border-style: solid;
          //  border-width: 6px 5px 0 5px;
          //  border-color: #5e5e5e transparent transparent transparent;
          //  display: block;
          //  -webkit-transform: rotateZ(-180deg);
          //  transform: rotateZ(-180deg);
          //  transition: all 0.2s;
          //}
        }
        &.sorting_asc {
          //&:before{
          //  -webkit-transform: rotateZ(0deg);
          //  transform: rotateZ(0deg);
          //  transition: all 0.2s;
          //}
        }
      }
    }
    tbody {
      td {
        color: #4a4e53;
        font-size: 14px;
        border-bottom: 1px dashed rgba(112, 112, 112, 0.27);
        font-weight: normal;
      }
      tr {
        &:hover {
          background: #fff;
        }
        &.active {
          background: #e3e2df;
        }
      }
    }
  }
}

.table_collapse {
  .datagrid {
    margin-right: 330px;
    table thead th.sorting_desc:before,
    table thead th.sorting_asc:before {
      display: none !important;
    }
  }
  .sidebar_details {
    right: 0;
    opacity: 1;
  }
  .table_overlay {
    display: block;
  }
}

.dataTables_wrapper {
  .dataTables_info,
  .dataTables_paginate {
    display: none !important;
  }
}

.clickable_td {
  color: #6f99cc;
  text-decoration: underline;
  &:hover {
    color: $red;
  }
}



.status {
  padding: 3px 10px;
  font-size: 12px;
  background: transparent;
  border-radius: 30px;
  cursor: pointer;
  &.active {
    background: #46a00d;
    color: #fff;
  }
  &.dormant {
    background: #ffc112;
    color: #775800;
  }
  &.suspended {
    background: #ff4512;
    color: #fff;
  }
  &.dark {
    background: #606060;
    color: #fff;
  }

}

.table_overlay{
  position: fixed;
  display: none;
  bottom: 0;
  right: 0;
  width: 400px;
  height: calc(100vh - 135px);
  background: $light_grey;
  border-left: $border_dark solid 1px;
  .overlay_content{
      height: calc(100vh - 186px);
      overflow-y: auto;
  }
  .title{
      border-bottom: $border_dark solid 1px;
      padding: 12px 15px;
      display: flex;
      a{
          color: #6F99CC;
          text-decoration: underline;
          font-size: 18px;
      }
      .button{
          margin-left: 15px;
      }
  }
  .info{
      margin: 15px;
      background-color: $white;
      border-radius: 15px;
      .row{
          display: flex;
          padding: 8px 15px;
          border-bottom: #ddd solid 1px;
          span{
              display: inline-flex;
              // flex-grow: 1;
              align-items: center;
              font-size: 13px;
              &:not(:first-of-type){
                  padding-left: 8px;
              }
              &.red{
                  color: $red;
              }
              &.token{
                  flex-direction: column;
                  align-items: start;
                      padding-left: 10px;
                  h4{
                      color: $red;
                  }
                  p{
                      font-size: 20px;
                  }
              }
          }
          .image{
              width: 30px;
              height: 30px;
              padding: 5px;
              flex-grow: unset;
              align-items: unset;
              img{
                  width: 100%;
              }
          }
      }
  }
  .products{
      padding: 15px;
      .row{
          height: 80px;
          margin-bottom: 15px;
          display: flex;
          align-items: stretch;
          background-color: $white;
          border-radius: 15px;
          .image{
              width: 120px;
              height: 80px;
              padding: 5px;
              display: flex;
              align-items: center;
              img{
                  width: 100%;
              }
          }
          .type{
              display: flex;
              align-items: center;
              flex-grow: 2;
              justify-content: center;
              padding: 15px;
              border-left: #ddd solid 1px;
          }
          .account{
              display: flex;
              flex-grow: 3;
              flex-direction: column;
              justify-content: center;
              padding: 15px;
              border-left: #ddd solid 1px;
          }
      }
  }
}
.close_icon{
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  opacity: 0.5;
  cursor: pointer;
  &:before, &:after{
    content: "";
    position: absolute;
    left: 7px;
    top: 2px;
    height: 12px;
    width: 2px;
    left: 11px;
    top: 0;
    height: 24px;
    background-color: #868582;
  }
  &:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &:hover{
    opacity:1;
  }
}
.sidebar_details {
  position: fixed;
  right: -370px;
  top: 135px;
  opacity: 0;
  width: 370px;
  background: $light_grey;
  border-left: $border_dark solid 1px;
  transition: right 0.3s ease, opacity 0.3s ease;
  height: calc(100vh - 135px);
  overflow-y: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  &::-webkit-scrollbar {
    width: 6px;
    margin: 5px;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    margin: 2px;
    border-radius: 10px;
    background-color: #c1c1c1;
  }

  .overlay_content {
    height: calc(100vh - 186px);
    overflow-y: auto;
  }
  .title {
    border-bottom: $border_dark solid 1px;
    padding: 12px 15px;
    display: flex;
    a {
      color: #6f99cc;
      text-decoration: underline;
      font-size: 18px;
    }
    .button {
      margin-left: 15px;
    }
  }
  .info {
    margin: 15px;
    background-color: $white;
    border-radius: 15px;
    .row {
      display: flex;
      padding: 8px 15px;
      border-bottom: #ddd solid 1px;
      span {
        display: inline-flex;
        // flex-grow: 1;
        align-items: center;
        font-size: 13px;
        &:not(:first-of-type) {
          padding-left: 8px;
        }
        &.red {
          color: $red;
        }
        &.token {
          flex-direction: column;
          align-items: start;
          padding-left: 10px;
          h4 {
            color: $red;
          }
          p {
            font-size: 20px;
          }
        }
      }
      .image {
        width: 30px;
        height: 30px;
        padding: 5px;
        flex-grow: unset;
        align-items: unset;
        img {
          width: 100%;
        }
      }
    }
  }
  .products {
    padding: 15px;
    .row {
      height: 80px;
      margin-bottom: 15px;
      display: flex;
      align-items: stretch;
      background-color: $white;
      border-radius: 15px;
      .image {
        width: 120px;
        height: 80px;
        padding: 5px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
      .type {
        display: flex;
        align-items: center;
        flex-grow: 2;
        justify-content: center;
        padding: 15px;
        border-left: #ddd solid 1px;
      }
      .account {
        display: flex;
        flex-grow: 3;
        flex-direction: column;
        justify-content: center;
        padding: 15px;
        border-left: #ddd solid 1px;
      }
    }
  }
}
.app_customer_layout{
  padding: 30px 40px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  .customer_overview{
      display: flex;
      min-height: 90px;
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      align-items: stretch;
      padding-right: 40px;
      margin-bottom: 30px;
      background-color: $white;
      .title{
          background-color: $light-grey;
          word-wrap: break-word;
          width: 100%;
        // display: flex;
          align-items: center;
        text-align: center;
        padding: 10px 10px;
        width: 200px;
          font-weight: 600;
          h3{
              margin: 0 auto;
              font-size: 23px;
          }
      }
      .details{
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 10px 20px;
          img{
              height: 20px;
              width: 20px;
              margin-right: 10px;
          }
          .location, .contact{
              display: flex;
              // flex-grow: 1;
              span{
                  display: flex;
                  align-items: center;
                  margin-right: 15px;
              }

        }

      }
      .close_icon{
          top: calc(50% - 15px);
          right: 15px;
          height: 30px;
          width: 30px;
          &:after, &:before{
              height: 30px;
          }
      }
  }
  .customer_products{
      height: auto;

      .product{
          position: relative;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          border-radius: 10px;
          border: #ddd solid 1px;
          margin:0 0 20px 0;
          overflow-y: auto;
          &.open{
              .close_icon{
                  display: flex;
              }
              .title{
                  height: unset;
                  .image{
                    width: 100%;
                  }
                  .account{
                      flex-direction: column;
                      align-items: start;
                      .type{
                          margin-right: 0;
                      }
                      .number{
                          h4{
                              font-weight: 400;
                          }
                          p{
                              font-weight: 400;
                          }
                      }
                  }
                  .activity{
                      flex-direction: column;
                      width: max-content;
                      .token{
                          flex-direction: row;
                          justify-content: start;
                          flex-basis: 50%;
                          border-bottom: #ddd solid 1px;
                          border-right: none;
                          padding: 0 10px 0 10px;
                          h4{
                              font-weight: 400;
                          }
                          .resend.button{
                              display: inline;
                              background-color: #FF4612;
                              border-radius: 30px;
                              font-size: 13px;
                              color: #fff;
                              padding: 3px 15px;
                          }
                          .token_status{
                              border-left: #ddd solid 1px;
                              border-right: 0;
                              margin-left: 7px;
                              padding-left: 18px;
                              justify-content: start;
                          }
                          .green.button{
                            background-color: #46a00d;
                            color: #fff;
                            font-size: 13px;
                            border-radius: 30px;
                            display: table;
                            padding: 3px 15px;
                          }

                        .dark-green.button {
                          background-color: #3c763d;
                          color: #fff;
                          font-size: 13px;
                          border-radius: 30px;
                          display: table;
                          padding: 3px 15px;
                        }
                          .red.button{
                            background-color: #FF4612;
                            color: #fff;
                            font-size: 13px;
                            border-radius: 30px;
                            display: table;
                            padding: 3px 15px;
                          }
                      }
                      .call{
                          .last_call, .reason{
                              display: inline;
                          }
                          .token_status{
                            border-right: 0;
                            // margin-left: 7px;
                            padding-left: 18px;
                            // margin-right: 40px;
                            justify-content: start;
                        }
                        .green.button{
                          background-color: #46a00d;
                          color: #fff;
                          font-size: 13px;
                          border-radius: 30px;
                          display: table;
                          padding: 3px 15px;
                        }

                        .dark-green.button {
                          background-color: #3c763d;
                          color: #fff;
                          font-size: 13px;
                          border-radius: 30px;
                          display: table;
                          padding: 3px 15px;
                        }
                        .red.button{
                          background-color: #FF4612;
                          color: #fff;
                          font-size: 13px;
                          border-radius: 30px;
                          display: table;
                          padding: 3px 15px;
                        }
                      }
                  }
              }
              .product_tabs{
                  opacity: 1;
                  height: fit-content;
              }
          }
          .close_icon{
              display: none;
              top: 19px;
          }
          .title{
              display: flex;
              height: 90px;
              width: 100%;

            .prod {
              border-right: #ddd solid 1px;
              width: 160px;
              .image{
                height: 70%;
                  padding: 3px 0;
                  img{
                      margin: 0 auto;
                      height: 80%;
                      display: flex;
                  }
              }
            }

              .account{
                  padding: 10px 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-right: #ddd solid 1px;
                  .type{
                      display: flex;
                      flex-basis: 40%;
                      margin-right: 20px;
                      h3{
                          font-size: 30px;
                          line-height: 37px;
                          font-weight: 400;
                      }
                  }
                  .number{
                      display: flex;
                      flex-direction: column;
                      flex-basis: 40%;
                      h4{
                          color: $red;
                          font-weight: 600;
                      }
                      p{
                          font-size: 19px;
                          line-height: 24px;
                          font-weight: 400;
                      }
                  }
              }
              .activity{
                  display: flex;
                  flex-direction: row;
                  flex-grow: 1;
                  .token{
                      display: flex;
                      justify-content: space-between;
                      flex-direction: row-reverse;
                      flex-basis: 100%;
                      align-items: center;
                      border-right: #ddd solid 1px;
                      border-bottom: none;
                      h4{
                          color: $red;
                          font-weight: 600;
                      }
                      p{
                          font-size: 15px;
                          line-height: 20px;
                      }
                      .resend.button{
                          display: none;
                          margin: 0 8px;
                          background-color: #FF4612;
                          border-radius: 30px;
                          font-size: 13px;
                          color: #fff;
                          padding: 3px 15px;
                      }
                      .token_status{
                          display: inline-flex;
                          height: 100%;
                          align-items: center;
                          justify-content: center;
                          flex-grow: 1;
                          border-right: #ddd solid 1px;
                          padding: 0 15px;
                      }
                      .token_info{
                         padding: 0 10px;
                      }
                      .green.button{
                        background-color: #46a00d;
                        color: #fff;
                        font-size: 13px;
                        border-radius: 30px;
                        display: table;
                        padding: 3px 15px;
                      }

                    .dark-green.button {
                      background-color: #3c763d;
                      color: #fff;
                      font-size: 13px;
                      border-radius: 30px;
                      display: table;
                      padding: 3px 15px;
                    }
                      .red.button{
                        background-color: #FF4612;
                        color: #fff;
                        font-size: 13px;
                        border-radius: 30px;
                        display: table;
                        padding: 3px 15px;
                      }
                  }
                  .call{
                      display: flex;
                      flex-basis: 50%;
                      align-items: center;
                      padding-left: 10px;
                      padding: 0 15px;
                      h4{
                          color: $red;
                          font-weight: 400;
                      }
                      p{
                          font-size: 20px;
                          line-height: 20px;
                      }
                      .last_call{
                          display: none;
                          margin-right: 25px;
                      }
                      .reason{
                          display: none;
                          margin-right: 25px;
                      }

                  }
              }
          }
          .product_tabs{
              opacity: 0;
              height: 0;
              display: flex;
              flex-direction: column;
              border-top: #ddd solid 1px;
              transition: right 0.3s ease, opacity 0.3s ease;
              .product_tabs_menu{
                  list-style-type: none;
                  border-bottom: $light_grey solid 1px;
                  text-align: center;
                  padding: 0 5px;
                  display: inherit;
                  li{
                      padding: 0 8px;
                      &.active{
                          font-weight: 600;
                          a{
                              &:after{
                                  display: block;
                              }
                          }
                      }
                      a{
                          padding: 15px 10px;
                          display: block;
                          position: relative;
                          color: $text_color;
                          &:after{
                              display: none;
                              content: "";
                              position: absolute;
                              width: 100%;
                              height: 3px;
                              background: $red;
                              bottom: 0;
                              right: 0;
                              display: none;
                          }
                      }

                  }

              }
              .tab_pane{
                padding: 20px;
              }
          }
      }
  }
}

table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin-bottom:25px;
  td, th {
    padding:14px 6px;
  }
  thead, tfoot{
    th {
      color:#4A4E53;
      font-family:$font-semibold;
      border-bottom:#ddd solid 2px;
    }
  }
  thead{
    th{
      cursor: pointer;
      position: relative;
      &.sorting_desc{
        color:$purple;
      }
    }
  }
  tbody{
    td{
      color: #4A4E53;
      font-size:14px;
      border-bottom:1px dashed rgba(112, 112, 112, 0.27);
      font-weight: normal;
    }
    tr{
      &:hover{
        background:#fff;
      }
      &.active{
        background: #e3e2df;
      }
    }
  }
}


.app_setup_layout{
  padding: 30px 40px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  position: relative;
  .setup_tab_menu{
      list-style-type: none;
      // display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 700px;
    flex-basis: 40%;
      .nav-item{
          background-color: $white;
          margin-right: 20px;
          border-radius: 13px;
          border: $light_grey solid 1px;
          margin-bottom: 20px;
          &.active{
              margin-right: 0;
              border-radius: 13px 0 0 13px;
              background-color: $light_grey;
              border-color: $border_dark;
              border-width: 1px 0 1px 1px;
              // margin-bottom: 20px;
              .nav-link{
                  .item-icon{
                      border: $red solid 3px;
                      svg{
                          color: $red;
                      }
                  }
              }
          }
          .nav-link{
              flex-direction: row;
              display: flex;
              padding: 16px 30px;
              .item-icon{
                  border: $grey solid 3px;
                  border-radius: 300px;
                  // padding: 20px;
                  width: 70px;
                  height: 70px;
                  svg{
                      width: 100%;
                      height: 100%;
                      padding: 5px 8px 8px 16px;
                      color: $grey;
                      fill: currentColor;
                      opacity: 1;
                  }
              }
              .item-title{
                  margin-left: 15px;
                  h4{
                      font-size: 26px;
                      font-weight: 400;
                  }
                  p{
                      color: $text_color;
                      font-size: 19px;
                  }
              }
         }
      }
  }
  .tab-content{
      background-color: $light_grey;
      position: relative;
      padding-bottom: 20px;
    flex-basis: 70%;
      border: $border_dark solid 1px;
      .tab_pane{
          padding: 0;
      }
      .form_header{
          border-bottom: $border_dark solid 1px;
          padding: 15px 20px;
          font-size: 18px;
          color: #616161;
          font-weight: bold;
      }
      .button_secondary{
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        font-size: 14px;
        padding: 10px 20px;
        border-radius: 28px;
        display: flex;
        margin: 0 20px 0 auto;
      }
      .form_group{
          display: flex;
          margin: 20px;
          background-color: $white;
          border-radius: 10px;
          position: relative;
          &.icon{
              width: calc(100% - 180px);
              svg{
                  position: absolute;
                  right: -50px;
                  width: 50px;
                  height: 50px;
                  color: #616161;
                  fill: currentColor;
              }
          }
          &.checkbox{
              background-color: transparent;
              flex-wrap: wrap;
          }
          .input{
              flex-grow: 1;
              flex-direction: column;
              display: flex;
              padding: 4px 14px;
              &:nth-of-type(2){
                  border-left: $border_dark solid 2px;
              }

            &:nth-of-type(3) {
              border-left: $border_dark solid 2px;
            }
          }

        .select-box {
          flex-grow: 1;
          flex-direction: column;
          display: flex;
          padding: 4px 14px;

          &:nth-of-type(2) {
            border-left: $border_dark solid 2px;
          }
        }
          .checkbox{
              display: flex;
              align-items: center;
              background-color: $white;
              border-radius: 10px;
              margin: 0 20px 10px 0;
              padding: 13px 20px;
              img{
                  height: 40px;
                  width: 40px;
                  margin: 0 16px;
              }
          }
          label{
              display: inline;
              font-size: 12px;
              font-weight: 600;
              color: $red;
          }


        input, textarea, select {
              font-size: 19px;
              border: none;
              background-color: transparent;
              &::placeholder{
                  color: #ccc;
              }
          }

        label.checkboxLabel {
          display: inline;
          font-size: 12px;
          font-weight: 600;
          color: $red;
        }
      }


  }
}

@media (max-width: 800px){

  .profile_switcher {
    background: #f7f7fc;
    padding: 8px;
    &.is-open {
      .profile_switcher__panel {
        opacity: 1;
        visibility: visible;
        left: 53px;
        top: 188px
      }
      .switcher_toggle .item_svg_icon {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
      }
    }
  }

  .profile_switcher__panel {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 8px;
    background: $white;
    left: 0;
    z-index: 1015;
    min-width: 400px;
    max-width: 600px;
    padding: 20px;
    border-radius: 24px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 36px 4px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease, opacity 0.3s ease;
  }


.sidebar_header {
  padding: 8px;
  display: flex;
  justify-content: center;
  .partner_switcher {
    display: flex;
    // position: relative;
    &.open {
      .partner_list {
        display: block;
        margin-top: 128px;
        margin-left: 58px;
        opacity: 1;
        z-index: 999;
      }
    }
    .partner_toggle {
      display: flex;
      align-items: center;
      // transition: all .5s;
      &:hover {
        svg {
          margin-top: 5px;
          opacity: 0.7;
        }
      }
      svg {
        margin-left: 10px;
        width: 23px;
        height: 23px;
        opacity: 0.4;
      }
    }
    .partner_list {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 60px;
      background-color: white;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 12px;
      padding: 15px 0;
      min-width: 330px;
      display: none;
      margin-top: -125%;
      max-height: 60vh;
      overflow-y: auto;
      opacity: 0;
      transition: margin-top 0.5s ease, opacity 0.5s ease;
      li {
        margin: 8px 14px 0;
        padding-bottom: 8px;
        border-bottom: #ddd solid 1px;
        display: flex;
        align-items: center;
        &.input {
          padding: 0 10px 10px;
          margin: 0;
          border-bottom: none;
          // position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 20px;
            width: 23px;
            height: 22px;
            background: url('../images/search_icon.svg') no-repeat left
              center;
          }
          input {
            width: 100%;
            padding: 5px 10px 5px 44px;
            font-size: 0.9rem;
            border: #ddd solid 1px;
            border-radius: 5px;
          }
        }
        span {
          display: inline-flex;
          &.image {
            width: 30px;
            height: 30px;
            padding: 5px;
          }
          &.text {
            margin-left: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

  .app_setup_layout{
    padding: 30px 40px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    position: relative;
    .setup_tab_menu{
        list-style-type: none;
        // display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 700px;
      flex-basis: 40%;
        .nav-item{
            background-color: $white;
            margin-right: 10px;
            border-radius: 13px;
            border: $light_grey solid 1px;
            margin-bottom: 10px;
            &.active{
                margin-right: 0;
                border-radius: 13px 0 0 13px;
                background-color: $light_grey;
                border-color: $border_dark;
                border-width: 1px 0 1px 1px;
                // margin-bottom: 20px;
                .nav-link{
                    .item-icon{
                        border: $red solid 3px;
                        svg{
                            color: $red;
                        }
                    }
                }
            }
            .nav-link{
                flex-direction: row;
                display: flex;
                padding: 10px 10px;
                .item-icon{
                    border: $grey solid 3px;
                    border-radius: 300px;
                    // padding: 20px;
                    width: 70px;
                    height: 70px;
                    display: none;
                    svg{
                        width: 100%;
                        height: 100%;
                        padding: 5px 8px 8px 16px;
                        color: $grey;
                        fill: currentColor;
                        opacity: 1;
                    }
                }
                .item-title{
                    margin-left: 15px;
                    h4{
                        font-size: 20px;
                        font-weight: 400;
                    }
                    p{
                        color: $text_color;
                        font-size: 15px;
                    }
                }
           }
        }
    }
    .tab-content{
        background-color: $light_grey;
        position: relative;
        padding-bottom: 20px;
      flex-basis: 70%;
        border: $border_dark solid 1px;
        .tab_pane{
            padding: 0;
        }
        .form_header{
            border-bottom: $border_dark solid 1px;
            padding: 15px 20px;
            font-size: 18px;
            color: #616161;
            font-weight: bold;
        }
        .button_secondary{
          color: #155724;
          background-color: #d4edda;
          border-color: #c3e6cb;
          font-size: 14px;
          padding: 10px 20px;
          border-radius: 28px;
          display: flex;
          margin: 0 20px 0 auto;
        }
        .form_group{
            display: flex;
            margin: 12px;
            background-color: $white;
            border-radius: 10px;
            position: relative;
            &.icon{
                width: calc(100% - 180px);
                svg{
                    position: absolute;
                    right: -50px;
                    width: 50px;
                    height: 50px;
                    color: #616161;
                    fill: currentColor;
                }
            }
            &.checkbox{
                background-color: transparent;
                flex-wrap: wrap;
            }
            .input{
                flex-grow: 1;
                flex-direction: column;
                display: flex;
                padding: 4px 14px;
            }

          .select-box {
            flex-grow: 1;
            flex-direction: column;
            display: flex;
            padding: 4px 14px;

            &:nth-of-type(2) {
              border-left: $border_dark solid 2px;
            }
          }
            .checkbox{
                display: flex;
                align-items: center;
                background-color: $white;
                border-radius: 10px;
                margin: 0 20px 10px 0;
                padding: 13px 20px;
                img{
                    height: 40px;
                    width: 40px;
                    margin: 0 16px;
                }
            }
            label{
                display: inline;
                font-size: 12px;
                font-weight: 600;
                color: $red;
            }


          input, textarea, select {
                font-size: 15px;
                border: none;
                background-color: transparent;
                &::placeholder{
                    color: #ccc;
                }
            }

          label.checkboxLabel {
            display: inline;
            font-size: 12px;
            font-weight: 600;
            color: $red;
          }
        }


    }
  }
}

@media (max-width: 576px){
  .app_breadcrumb{
    padding: 10px 20px;
  }

  .profile_switcher {
    background: #f7f7fc;
    padding: 8px;
    &.is-open {
      .profile_switcher__panel {
        opacity: 1;
        visibility: visible;
        left: 32px;
        top: 71px
      }
      .switcher_toggle .item_svg_icon {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
      }
    }
  }

  .profile_switcher__panel {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 8px;
    background: $white;
    left: 0;
    z-index: 1015;
    min-width: 360px;
    max-width: 600px;
    padding: 20px;
    border-radius: 24px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 36px 4px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease, opacity 0.3s ease;
  }


.sidebar_header {
  padding: 8px;
  display: flex;
  justify-content: center;
  .partner_switcher {
    display: flex;
    // position: relative;
    &.open {
      .partner_list {
        display: block;
        margin-top: 6px;
        margin-left: 30px;
        opacity: 1;
        z-index: 999;
      }
    }
    .partner_toggle {
      display: flex;
      align-items: center;
      // transition: all .5s;
      &:hover {
        svg {
          margin-top: 5px;
          opacity: 0.7;
        }
      }
      svg {
        margin-left: 10px;
        width: 23px;
        height: 23px;
        opacity: 0.4;
      }
    }
    .partner_list {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 60px;
      background-color: white;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 12px;
      padding: 15px 0;
      min-width: 330px;
      display: none;
      margin-top: -125%;
      max-height: 60vh;
      overflow-y: auto;
      opacity: 0;
      transition: margin-top 0.5s ease, opacity 0.5s ease;
      li {
        margin: 8px 14px 0;
        padding-bottom: 8px;
        border-bottom: #ddd solid 1px;
        display: flex;
        align-items: center;
        &.input {
          padding: 0 10px 10px;
          margin: 0;
          border-bottom: none;
          // position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 20px;
            width: 23px;
            height: 22px;
            background: url('../images/search_icon.svg') no-repeat left
              center;
          }
          input {
            width: 100%;
            padding: 5px 10px 5px 44px;
            font-size: 0.9rem;
            border: #ddd solid 1px;
            border-radius: 5px;
          }
        }
        span {
          display: inline-flex;
          &.image {
            width: 30px;
            height: 30px;
            padding: 5px;
          }
          &.text {
            margin-left: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

  .app_setup_layout{
    padding: 10px 10px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    position: relative;
    .setup_tab_menu{
        list-style-type: none;
        // display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 700px;
      flex-basis: 100%;
        .nav-item{
            background-color: $white;
            // margin-right: 10px;
            border-radius: 13px;
            border: $light_grey solid 1px;
            margin-bottom: 10px;
            &.active{
                margin-right: 0;
                border-radius: 13px 13px 13px 13px;
                background-color: $light_grey;
                border-color: $border_dark;
                border-width: 1px 1px 1px 1px;
                // margin-bottom: 20px;
                .nav-link{
                    .item-icon{
                        border: $red solid 3px;
                        svg{
                            color: $red;
                        }
                    }
                }
            }
            .nav-link{
                flex-direction: row;
                display: flex;
                padding: 10px 10px;
                .item-icon{
                    border: $grey solid 3px;
                    border-radius: 300px;
                    // padding: 20px;
                    width: 70px;
                    height: 70px;
                    display: none;
                    svg{
                        width: 100%;
                        height: 100%;
                        padding: 5px 8px 8px 16px;
                        color: $grey;
                        fill: currentColor;
                        opacity: 1;
                    }
                }
                .item-title{
                    margin-left: 15px;
                    h4{
                        font-size: 20px;
                        font-weight: 400;
                    }
                    p{
                        color: $text_color;
                        font-size: 15px;
                    }
                }
           }
        }
    }
    .tab-content{
        background-color: $light_grey;
        position: relative;
        padding-bottom: 20px;
        flex-basis: 100%;
        border: $border_dark solid 1px;
        .tab_pane{
            padding: 0;
        }
        .form_header{
            border-bottom: $border_dark solid 1px;
            padding: 15px 20px;
            font-size: 18px;
            color: #616161;
            font-weight: bold;
        }
        .button_secondary{
          color: #155724;
          background-color: #d4edda;
          border-color: #c3e6cb;
          font-size: 14px;
          padding: 10px 20px;
          border-radius: 28px;
          display: flex;
          margin: 0 20px 0 auto;
        }
        .form_group{
            display: flex;
            margin: 12px;
            background-color: $white;
            border-radius: 10px;
            position: relative;
            &.icon{
                width: calc(100% - 180px);
                svg{
                    position: absolute;
                    right: -50px;
                    width: 50px;
                    height: 50px;
                    color: #616161;
                    fill: currentColor;
                }
            }
            &.checkbox{
                background-color: transparent;
                flex-wrap: wrap;
            }
            .input{
                flex-grow: 1;
                flex-direction: column;
                display: flex;
                padding: 4px 14px;
            }

          .select-box {
            flex-grow: 1;
            flex-direction: column;
            display: flex;
            padding: 4px 14px;

            &:nth-of-type(2) {
              border-left: $border_dark solid 2px;
            }
          }
            .checkbox{
                display: flex;
                align-items: center;
                background-color: $white;
                border-radius: 10px;
                margin: 0 20px 10px 0;
                padding: 13px 20px;
                img{
                    height: 40px;
                    width: 40px;
                    margin: 0 16px;
                }
            }
            label{
                display: inline;
                font-size: 12px;
                font-weight: 600;
                color: $red;
            }


          input, textarea, select {
                font-size: 15px;
                border: none;
                background-color: transparent;
                &::placeholder{
                    color: #ccc;
                }
            }

          label.checkboxLabel {
            display: inline;
            font-size: 12px;
            font-weight: 600;
            color: $red;
          }
        }


    }
  }
}

.flag {
  font-size: 22px;
  padding: 0 5px;
}


.nav-dynamic-header-element {
  padding:12px 32px 12px 12px!important;
}

.mt-15px{
  margin-top:15px;
}

.nav-separator {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin-bottom:10px;
  padding: 0;

}
