/*!

 =========================================================
 * Paper Dashboard Angular - V1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-angular
 * Copyright 2017 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-angular/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// @import "paper/variables";
// @import "paper/mixins";

// @import "paper/typography";

// Core CSS
// @import "paper/misc";
// @import "paper/sidebar-and-main-panel";
// @import "paper/buttons";
// @import "paper/inputs";

// @import "paper/alerts";
// @import "paper/tables";

// @import "paper/checkbox-radio";
// @import "paper/navbars";
// @import "paper/footers";

// Fancy Stuff

// @import "paper/animate";
// @import "paper/dropdown";
// @import "paper/cards";
// @import "paper/chartist";
// @import "paper/responsive";

// @import "paper/plugins/fixed-plugin";

@import "main";
// @import '@swimlane/ngx-datatable/index.css';
// @import '@swimlane/ngx-datatable/themes/material.scss';
// @import '@swimlane/ngx-datatable/assets/icons.css';



// ngx datatable
.datatable-header{
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  font-weight: 800;
  margin-top: 20px;
}

.datatable-body-cell{
  word-wrap:break-word
}

datatable-body-row {
  border-bottom: 1px dashed #ddd;
  padding-left: 5px;
  padding-top: 10px;
}

datatable-body-row:hover {
  background-color: white;
}

.ngx-datatable .datatable-footer .datatable-pager {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 80%;
  flex: 1 1 80%;
  text-align: right;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}

.datatable-pager .pager, .ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
.datatable-pager .pager, .ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
.datatable-pager li {
  vertical-align: middle;
}

.datatable-pager .pager li.disabled a {
  cursor: not-allowed;
}

.datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li, .ngx-datatable .datatable-footer .datatable-pager .pager li a {
  outline: none;
}

 .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}


// .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
//   cursor: default;
//     color: #818181;
//     font-weight: bold;
//     font-size: 18px;
//     background-color: #fff;
//     border-color: #fff #fff #FF4612 #fff;
//     border-top-color: rgb(255, 255, 255);
//     border-right-color: rgb(255, 255, 255);
//     border-bottom-color: rgba(255, 70, 18, 1);
//     border-left-color: rgb(255, 255, 255);
//     border-bottom-width: initial;
// }


// .nav-tabs .nav-link {
//   color: #818181;
//   font-size: 18px;
//   background-color: #fff;
//   border-color: #fff #fff #FF4612 #fff;
//   border-top-color: rgb(255, 255, 255);
//   border-right-color: rgb(255, 255, 255);
//   border-bottom-color: rgba(255, 70, 18, 1);
//   border-left-color: rgb(255, 255, 255);
//   border-bottom-width: 0;
// }

// .nav-tabs {
//   border-bottom: 0;
// }



@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'bootstrap/scss/_modal.scss';
@import 'bootstrap/scss/_buttons.scss';
@import 'bootstrap/scss/_badge.scss';
@import 'bootstrap/scss/_close.scss';
@import 'bootstrap/scss/_popover.scss';
@import 'bootstrap/scss/_tooltip.scss';
@import 'bootstrap/scss/_progress.scss';
@import 'bootstrap/scss/_grid.scss';
