/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

/* Margin stylings */
.m-0{
    margin: 0;
}

.m-40 {
    margin: 40px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-r-20 {
    margin-right: 15px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-20 {
    margin-top: 15px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 15px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-40 {
    margin-bottom: 40px;
}

/* Padding Styling */

.p-r-5 {
    margin-right: 5px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-r-15 {
    padding-right: 15px;
}

.p-r-20 {
    padding-right: 15px;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-40 {
    padding-right: 40px;
}

.p-l-5 {
    padding-left: 5px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-l-15 {
    padding-left: 15px;
}

.p-l-20 {
    padding-left: 15px;
}

.p-l-30 {
    padding-left: 30px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-t-5 {
    padding-top: 5px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-15 {
    padding-top: 15px;
}

.p-t-20 {
    padding-top: 15px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-40 {
    padding-top: 40px;
}

.p-b-5 {
    padding-bottom: 5px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.p-b-15 {
    padding-bottom: 15px;
}

.p-b-20 {
    padding-bottom: 15px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-b-40 {
    padding-bottom: 40px;
}

.w-70{
    width: 70px;
}

.rows {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md {
  flex-grow: 1;
  max-width: 100%;
  -webkit-box-flex: 1;
}

.text-white{
    color: #fff;
}


/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}


.select2-selection {
    width: 100%;
}

.popover-body {
  height: 110px;
  overflow-y: auto;
  white-space: pre-wrap;
  /* margin-top: 10px; */
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 4px;
  border: 1px solid rgba(255, 255, 255, .2);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .2);
}

/* ::-webkit-scrollbar-thumb:vertical{
  background: rgba(255, 255, 255, .2);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, .2);
}

::-webkit-scrollbar-thumb:horizontal {
    background: rgba(255, 255, 255, .2);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, .2);
} */

.custom-window-class,
.custom-window-class > .modal-dialog,
.custom-window-class > .modal-dialog > .modal-content {
  width: 1100px!important;
  max-width: 1100px!important;
  min-width: 1100px!important;
}

.spinner-inline {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height:100%;
    width:100%;
    display:flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }
  
  .spinner-inline .loading-inline {
    font-size: 16px;
    font-weight: bold;
    color:white;
  }
  