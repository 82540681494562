@media only screen and (max-width: 1600px){
    .header{
    	 //background:red;
    }
}//Max width 1600


@media only screen and (max-width: 1366px){
    .header{
    	 //background:blue;
    }
}//Max width 1280

@media only screen and (max-width: 1280px){
    .header{
    	 //background:brown;
    }
	.wrap {
		max-width: none;
		padding: 0 40px;
	}

	.wizard_menu li {
		padding: 26px 30px;
	}
}//Max width 1280


@media only screen and (max-width: 1100px){
	.header{
    	 //background:green;
    }

}//Max width 1100


@media only screen and (max-width: 960px){
	.header{
    	 //background:purple;
    }

}//max width 960

@media only screen and (max-width: 768px){
	.header{
    	 //background:black;
    }
}//max width 768


@media only screen and (max-width: 500px){
 	.header{
    	 //background:yellow;
    }
}//max 500px


@media only screen and (max-width: 330px){
	.header{
    	 //background:pink;
    }
}//max 330px


// Print Styles
 
@media print {

	* {
		background: transparent !important; 
		color: black !important; 
		text-shadow: none !important; 
		filter:none !important; 
		-ms-filter: none !important; 
	} 
	
	a, 
	a:visited { 
		text-decoration: underline; 
	}
	
	a[href]:after { 
		content: " (" attr(href) ")"; 
	}
	
	abbr[title]:after { 
		content: " (" attr(title) ")"; 
	}
	
	.ir a:after, 
	a[href^="javascript:"]:after, 
	a[href^="#"]:after { 
		content: ""; 
	} 
	
	pre, 
	blockquote { 
		border: 1px solid #999; 
		page-break-inside: avoid; 
	}
	
	thead { 
		display: table-header-group; 
	}
	
	tr, 
	img { 
		page-break-inside: avoid; 
	}
	
	img { 
		max-width: 100% !important; 
	}
	
	@page { 
		margin: 0.5cm; 
	}
	
	p, 
	h2, 
	h3 { 
		orphans: 3; 
		widows: 3; 
	}
	
	h2, 
	h3 { 
		page-break-after: avoid; 
	}
	
}

// Legacy Grid support ie 10/11

@media all and (-ms-high-contrast:none) {
	.grid-container {
	  display: -ms-grid;
	  -ms-grid-columns: 1fr 2.5fr;
	  -ms-grid-rows: 1fr;
	}
  
	.Sidebar {
	  -ms-grid-row: 1;
	  -ms-grid-row-span: 1;
	  -ms-grid-column: 1;
	  -ms-grid-column-span: 1;
	}
  
	.Modules {
	  -ms-grid-row: 1;
	  -ms-grid-row-span: 1;
	  -ms-grid-column: 2;
	  -ms-grid-column-span: 1;
	}
  
  }